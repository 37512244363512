'use client';

import {MotionConfig, Transition} from 'framer-motion';

const defaultTransition: Transition = {
  ease: [0.25, 1, 0.5, 1]
};

const MotionProvider = ({children}) => {
  return <MotionConfig transition={defaultTransition}>{children}</MotionConfig>;
};

export default MotionProvider;
